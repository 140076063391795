import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

export default class NewRequestorPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet titleTemplate="%s | Pathlab">
          <title>{`New Requestor`}</title>
          <meta
            name="description"
            content={`Provide Pathlab with your details so that we can set you up in our system to ensure you receive results.`}
          />
        </Helmet>
        <section className="section">
          <div className="container content-border">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="content">
                  <iframe
                    id="new-requestor-form"
                    title="New Requestor Form"
                    src="https://forms.office.com/Pages/ResponsePage.aspx?id=AWoWqGyZBU2T4SubP7FHPuMFEfVfJNdMrPLc4msvdpFUQUJDMThMVkpYVVVUMVhRVDc5R0g5RExMMy4u&embed=true"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
